import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/common/Header';
import Hero from './components/Hero';
import LegalDisclosure from './components/personal/LegalDisclosure';
import TermsAndConditions from './components/personal/TermsAndConditions';
import PrivacyPolicy from './components/personal/PrivacyPolicy';
import Features from './components/personal/Features';
import Testimonials from './components/personal/Testimonials';
import Pricing from './components/personal/Pricing';
import FAQ from './components/personal/FAQ';
import { LanguageProvider } from './LanguageContext';
import './App.css';

const App = () => {
  const [mode, setMode] = React.useState(() => {
    return localStorage.getItem('mode') || 'light';
  });

  const theme = createTheme({
    palette: {
      mode,
      background: {
        default: mode === 'light' ? '#fff' : '#000',
      },
      text: {
        primary: mode === 'light' ? '#000' : '#fff',
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === 'light' ? '#fff' : '#000',
          },
        },
      },
    },
  });

  const toggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('mode', newMode);
  };

  useEffect(() => {
    document.title = 'Tenno AI';
  }, [mode]);

  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header mode={mode} toggleMode={toggleMode} showMenu={false} />
          <div className="main-content" style={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/personal" element={
                <>
                  <Features />
                  <Testimonials />
                  <Pricing />
                  <FAQ />
                </>
              } />
              <Route path="/personal/legaldisclosure" element={<LegalDisclosure />} />
              <Route path="/personal/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/personal/privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;
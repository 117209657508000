import React from 'react';
import { Box, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';

const Footer = ({ showLegalDisclosure }) => {
  return (
    <Box
      component="footer"
      sx={{
        width: '100%',
        bgcolor: 'background.default',
        color: 'text.primary',
        p: 1,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        zIndex: 3,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
        <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px' }}>
          <FacebookIcon fontSize="small" />
        </Box>
        <Box className="footer-icon" sx={{ mx: 1, fontSize: '10px' }}>
          <XIcon fontSize="small" />
        </Box>
      </Box>
      <Typography variant="body2" component="p" sx={{ fontSize: '10px' }}>
        &copy; {new Date().getFullYear()} Tenno AI. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;

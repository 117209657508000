import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Box,
    Divider,
} from '@mui/material';
import { LanguageContext } from '../../LanguageContext';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const enterprisePlan = {
    EN: {
        title: 'Enterprise',
        price: 'Ask',
        description: [
            'Customized AI solutions',
            'Access to all features',
            'Dedicated enterprise support',
            'Unlimited tokens per month',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
        icon: <BusinessCenterIcon />,
        tryButtonText: 'Try it now with Personal Plan',
        tryButtonLink: '/personal#pricing',
    },
    JP: {
        title: 'エンタープライズ',
        price: '要相談',
        description: [
            'カスタマイズされたAIソリューション',
            '全ての機能へのアクセス',
            '専任エンタープライズサポート',
            '無制限のトークン付与',
        ],
        buttonText: 'お問い合わせ',
        buttonVariant: 'outlined',
        icon: <BusinessCenterIcon />,
        tryButtonText: 'まずはパーソナルプランで体験する',
        tryButtonLink: '/personal#pricing',
    },
};

const Pricing = () => {
    const { language } = React.useContext(LanguageContext);
    const tier = enterprisePlan[language];

    return (
        <Container id="pricing" sx={{ py: { xs: 8, sm: 16 } }}>
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={8}
            >
                {language === 'EN' ? 'Pricing' : '価格'}
            </Typography>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Card
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                            border: '1px solid',
                            borderColor: 'primary.main',
                            background: 'linear-gradient(#033363, #021F3B)',
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    mb: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    color: 'grey.100',
                                }}
                            >
                                <Typography component="h3" variant="h6">
                                    {tier.icon && tier.icon}
                                    {tier.title}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    color: 'grey.50',
                                }}
                            >
                                <Typography component="h3" variant="h2">
                                    ¥{tier.price}
                                </Typography>
                                <Typography component="h3" variant="h6">
                                    &nbsp; {language === 'EN' ? 'per month' : '/ 月'}
                                </Typography>
                            </Box>
                            <Divider
                                sx={{
                                    my: 2,
                                    opacity: 0.2,
                                    borderColor: 'grey.500',
                                }}
                            />
                            {tier.description.map((line) => (
                                <Box
                                    key={line}
                                    sx={{
                                        py: 1,
                                        display: 'flex',
                                        gap: 1.5,
                                        alignItems: 'center',
                                    }}
                                >
                                    <CheckCircleRoundedIcon
                                        sx={{
                                            width: 20,
                                            color: 'primary.light',
                                        }}
                                    />
                                    <Typography
                                        component="text"
                                        variant="subtitle2"
                                        sx={{
                                            color: 'grey.200',
                                        }}
                                    >
                                        {line}
                                    </Typography>
                                </Box>
                            ))}
                        </CardContent>
                        <CardActions>
                            <Button fullWidth variant={tier.buttonVariant} color="primary">
                                {tier.buttonText}
                            </Button>
                        </CardActions>
                        <CardActions>
                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                href={tier.tryButtonLink}
                            >
                                {tier.tryButtonText}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Pricing;

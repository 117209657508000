import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    CardHeader,
    Avatar,
} from '@mui/material';
import { LanguageContext } from '../../LanguageContext';

const userTestimonials = {
    EN: [
        {
            avatar: <Avatar alt="Customer 1" src="/assets/images/avatar/1.png" />,
            title: 'Software Engineer',
            testimonial:
                "Tenno AI has completely transformed my approach to daily tasks. The AI insights are incredibly helpful!",
        },
        {
            avatar: <Avatar alt="Customer 2" src="/assets/images/avatar/2.png" />,
            title: 'Project Manager',
            testimonial:
                "I love the progress tracking feature. It keeps me motivated to achieve my goals.",
        },
        {
            avatar: <Avatar alt="Customer 3" src="/assets/images/avatar/3.png" />,
            title: 'Marketing Specialist',
            testimonial:
                "The community aspect is fantastic. I've met so many supportive people with similar interests.",
        },
    ],
    JP: [
        {
            avatar: <Avatar alt="Customer 1" src="/assets/images/avatar/1.png" />,
            title: 'ソフトウェアエンジニア',
            testimonial:
                "Tenno AIは私の日常のタスクのアプローチを完全に変えました。AIの提案がとても役立ちます！",
        },
        {
            avatar: <Avatar alt="Customer 2" src="/assets/images/avatar/2.png" />,
            title: 'プロジェクトマネージャー',
            testimonial:
                "進捗追跡機能が大好きです。目標達成のモチベーションを保つことができます。",
        },
        {
            avatar: <Avatar alt="Customer 3" src="/assets/images/avatar/3.png" />,
            title: 'マーケティングスペシャリスト',
            testimonial:
                "コミュニティの側面が素晴らしいです。同じ興味を持つ多くのサポートしてくれる人々と出会いました。",
        },
    ],
};

const Testimonials = () => {
    const { language } = React.useContext(LanguageContext);

    return (
        <Container
            id="testimonials"
            sx={{
                py: { xs: 8, sm: 16 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={8}
            >
                {language === 'EN' ? 'Testimonials' : 'お客様の声'}
            </Typography>
            <Grid container spacing={4}>
                {userTestimonials[language].map((testimonial, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                    {testimonial.testimonial}
                                </Typography>
                            </CardContent>
                            <CardHeader
                                avatar={testimonial.avatar}
                                title={testimonial.title}
                                sx={{ alignSelf: 'flex-end', mr: 2, mb: 2 }}
                            />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Testimonials;

import React, { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../common/Header';
import { LanguageContext } from '../../LanguageContext';
import { Typography, Container, Box } from '@mui/material';
import Footer from './PersonalFooter';
import legalDisclosure from '../../content/legalDisclosure.json';

const LegalDisclosure = () => {
  const { language } = useContext(LanguageContext);
  const [mode, setMode] = React.useState(() => {
    return localStorage.getItem('mode') || 'light';
  });

  const theme = createTheme({
    palette: {
      mode,
      background: {
        default: mode === 'light' ? '#fff' : '#000',
      },
      text: {
        primary: mode === 'light' ? '#000' : '#fff',
      },
    },
  });

  const toggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('mode', newMode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('mode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  const currentContent = legalDisclosure[language] || legalDisclosure.JP;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header mode={mode} toggleMode={toggleMode} showMenu={true} showAuthButtons={true} />
        <main className="main-content">
          <Container maxWidth="md">
            <Box sx={{ py: 4, mt: 8 }}>
              <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4 }}>
                {currentContent.title}
              </Typography>
              {currentContent.sections.map((section, index) => (
                <React.Fragment key={index}>
                  <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    {section.title}
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    {section.content}
                  </Typography>
                </React.Fragment>
              ))}
            </Box>
          </Container>
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default LegalDisclosure;
import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { LanguageContext } from '../../LanguageContext';

const Hero = () => {
    const { language } = useContext(LanguageContext);

    const content = {
        EN: {
            title: 'Welcome to Tenno AI',
            subtitle: 'Empowering your decisions with AI-driven insights.',
        },
        JP: {
            title: 'Tenno AIへようこそ',
            subtitle: 'AIが導く洞察で、あなたの意思決定を支援します。',
        },
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                background: `url("/assets/images/personal-hero.jpeg") no-repeat center center`,
                backgroundSize: 'cover',
                color: 'white',
                textAlign: 'center',
                flexDirection: 'column',
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1, // フィルターの優先度を適度に設定
                },
                '& > *': {
                    zIndex: 2, // コンテンツの優先度をフィルターより高く設定
                },
            }}
        >
            <Typography variant="h2" component="h1" sx={{ mb: 2 }}>
                {content[language].title}
            </Typography>
            <Typography variant="h6" component="p">
                {content[language].subtitle}
            </Typography>
        </Box>
    );
};

export default Hero;

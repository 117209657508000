import React, { useContext } from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LanguageContext } from '../../LanguageContext';

const Footer = () => {
    const { language } = useContext(LanguageContext);
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                width: '100%',
                bgcolor: theme.palette.background.default,
                color: theme.palette.text.primary,
                p: 2,
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                mt: 'auto', // これによりフッターがページの最下部に配置されます
            }}
        >
            <Typography variant="body2">
                <Link href="/personal/legaldisclosure" sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                    {language === 'EN' ? 'Legal Disclosure' : '特定商取引法に基づく表記'}
                </Link>
            </Typography>
            <Typography variant="body2">
                <Link href="/personal/termsandconditions" sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                    {language === 'EN' ? 'Terms and Conditions' : '利用規約'}
                </Link>
            </Typography>
            <Typography variant="body2">
                <Link href="/personal/privacypolicy" sx={{ color: theme.palette.text.primary, textDecoration: 'none' }}>
                    {language === 'EN' ? 'Privacy Policy' : 'プライバシーポリシー'}
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;

import React from 'react';
import {
    Container,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LanguageContext } from '../../LanguageContext';

const faqData = {
    EN: [
        {
            question: 'How does Tenno AI generate insights?',
            answer:
                'Our AI analyzes input data and uses advanced algorithms to provide personalized suggestions.',
        },
        {
            question: 'Can I try Tenno AI for free?',
            answer:
                'Yes, we offer a free plan with basic features so you can experience the benefits before upgrading.',
        },
        {
            question: 'Is my data secure?',
            answer:
                'Absolutely. We prioritize privacy and protect your information using the latest security measures.',
        },
        {
            question: 'How do I contact support?',
            answer:
                'You can reach our support team through the app or by visiting our contact page.',
        },
        {
            question: 'What features does Tenno AI offer?',
            answer:
                'Tenno AI provides AI-generated insights, progress tracking, goal setting, and community support.',
        },
    ],
    JP: [
        {
            question: 'Tenno AIはどのように提案を生成しますか？',
            answer:
                'Tenno AIは入力データを分析し、高度なアルゴリズムを使用して個別化された提案を提供します。',
        },
        {
            question: 'Tenno AIを無料で試せますか？',
            answer:
                'はい、基本機能を提供する無料プランをご用意していますので、アップグレードする前にそのメリットを体験できます。',
        },
        {
            question: '私のデータは安全ですか？',
            answer:
                'もちろんです。プライバシーを最優先し、最新のセキュリティ対策を用いて情報を保護しています。',
        },
        {
            question: 'サポートに連絡するにはどうすればいいですか？',
            answer:
                'アプリ内またはお問い合わせページからサポートチームにご連絡いただけます。',
        },
        {
            question: 'Tenno AIにはどんな機能がありますか？',
            answer:
                'Tenno AIは、AI生成の提案、進捗追跡、目標設定、コミュニティサポートなどの機能を提供します。',
        },
    ],
};

const FAQ = () => {
    const [expanded, setExpanded] = React.useState(false);
    const { language } = React.useContext(LanguageContext);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container
            id="faq"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
            }}
        >
            <Typography
                component="h2"
                variant="h4"
                color="text.primary"
                textAlign="left"
                mb={4}
            >
                {language === 'EN' ? 'Frequently Asked Questions' : 'よくある質問'}
            </Typography>
            <Box sx={{ width: '100%' }}>
                {faqData[language].map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}d-content`}
                            id={`panel${index}d-header`}
                        >
                            <Typography component="h3" variant="subtitle1">
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" sx={{ maxWidth: '100%' }}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQ;

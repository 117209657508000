import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/common/Header';
import Hero from './components/personal/Hero';
import Features from './components/personal/Features';
import Testimonials from './components/personal/Testimonials';
import Pricing from './components/personal/Pricing';
import FAQ from './components/personal/FAQ';
import LegalDisclosure from './components/personal/LegalDisclosure';
import TermsAndConditions from './components/personal/TermsAndConditions';
import PrivacyPolicy from './components/personal/PrivacyPolicy';
import { LanguageProvider } from './LanguageContext';
import PersonalFooter from './components/personal/PersonalFooter';

const TennoPersonal = () => {
  const [mode, setMode] = React.useState(() => {
    return localStorage.getItem('mode') || 'light';
  });

  const location = useLocation();

  const theme = createTheme({
    palette: {
      mode,
      background: {
        default: mode === 'light' ? '#fff' : '#000',
      },
      text: {
        primary: mode === 'light' ? '#000' : '#fff',
      },
    },
  });

  const toggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('mode', newMode);
  };

  useEffect(() => {
    document.title = 'Tenno AI';
  }, [mode]);

  // 特定のページの場合に他のセクションを表示しないようにする
  const isLegalDisclosure = location.pathname === '/personal/legaldisclosure';
  const isTermsAndConditions = location.pathname === '/personal/termsandconditions';
  const isPrivacyPolicy = location.pathname === '/personal/privacypolicy';

  const showAdditionalContent = !(isLegalDisclosure || isTermsAndConditions || isPrivacyPolicy);

  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header mode={mode} toggleMode={toggleMode} showMenu={true} showAuthButtons={true} />
          <main className="main-content" style={{ overflowY: 'auto', flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="features" element={<Features />} />
              <Route path="testimonials" element={<Testimonials />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="legaldisclosure" element={<LegalDisclosure />} />
              <Route path="termsandconditions" element={<TermsAndConditions />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
            </Routes>
            {showAdditionalContent && (
              <>
                <Features />
                <Testimonials />
                <Pricing />
                <FAQ />
              </>
            )}
          </main>
          <PersonalFooter />
        </div>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default TennoPersonal;